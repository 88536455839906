import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

const PrivacyPage = () => (
    <Layout>
        <SEO title="Privacy Page" />
        <div className="mt-20 max-w-2xl mx-auto pb-12 text-sm">
            <h2 className="text-lg font-medium pt-4">Privacy Policy</h2>
            <p className="leading-7">
                IndieNuggets has established this privacy policy to explain to
                you how your information is protected, collected, and used on its website
                indienuggets.com (the “Site”), IndieNuggets may update this policy at any time.
                IndieNuggets will provide notice of materially significant changes to this privacy
                policy by posting notice on the Site.
            </p>

            <h2 className="text-lg font-medium pt-4">Protecting Your Privacy</h2>
            <p className="leading-7">
                IndieNuggets makes all attempts to protect your private information. IndieNuggets
                will not share your personal information with any third parties without your consent
                unless otherwise specified in this Policy.
            </p>

            <p className="leading-7">
                IndieNuggets, or people who post on the Site, may provide links to third party
                websites, which may have different privacy practices. IndieNuggets is not
                responsible for, and does not have any control over, the privacy policies of those
                third party websites, and encourages all users to read the privacy policies of each
                and every website visited.
            </p>

            <h2 className="text-lg font-medium pt-4">Use of Website by Minors</h2>
            <p className="leading-7">
                IndieNuggets does not knowingly collect any information from persons under 13 years
                of age. Further, the use of the Site, subscribing to IndieNuggets' subscription
                services, and/or buying any products from the Site is only knowingly allowed by
                individuals who are over the age of 18 and can enter into legally binding contracts.
            </p>

            <h2 className="text-lg font-medium pt-4">Data IndieNuggets Collects</h2>
            <p className="leading-7">
                IndieNuggets may collect your name, email address, physical address, and phone
                number for verification purposes if you purchase a subscription and/or any products
                from IndieNuggets.
            </p>

            <p className="leading-7">
                IndieNuggets sometimes collects your email address, for purposes such as sending
                email newsletters, product release information, sale information, and authenticating
                your account. By submitting your email IndieNuggets, you consent to emails from
                IndieNuggets.
            </p>

            <p className="leading-7">
                IndieNuggets sometimes collects your phone number for the purpose of calling you
                about purchases made through IndieNuggets. By submitting your phone number to
                IndieNuggets, you consent to phone calls from IndieNuggets, even if such number is
                registered through a state or federal "Do Not Call" list.
            </p>

            <p className="leading-7">
                IndieNuggets may collect personal information if you provide such information in
                feedback or comments or if you contact IndieNuggets directly. Any information posted
                on the site is not confidential. Please do not post anything on IndieNuggets that
                you would like to keep private.
            </p>

            <p className="leading-7">
                The Site’s weblogs collect standard weblog entries for each page served, including
                your IP address, page URL, and timestamp. Weblogs help IndieNuggets diagnose
                problems with our server, to administer the Site, and to otherwise provide service
                to you. In addition, IndieNuggets may collect other information from your
                interaction with our Site, services, content, and advertising, including computer
                and connection information, statistics on page views, traffic to and from the Site,
                ad data, IP address, demographic and navigational data, and standard weblog
                information. This information may be obtained for IndieNuggets from a third party
                provider including, but not limited to, through the use of Google Analytics.
                IndieNuggets may use this information for marketing, commercial, or any other
                purpose as IndieNuggets sees fit.
            </p>

            <p className="leading-7">
                IndieNuggets may collect personal information if you provide such information in
                feedback or comments or if you contact IndieNuggets directly. Any information posted
                on the site is not confidential. Please do not post anything on the Site that you
                would like to keep private.
            </p>

            <h2 className="text-lg font-medium pt-4">Data IndieNuggets Stores</h2>
            <p className="leading-7">
                All information posted to this Site may be stored in the Site’s database, even after
                "deletion”, and may be archived elsewhere.
            </p>

            <p className="leading-7">
                The Site’s weblogs and other records are stored indefinitely. This may include any
                information you supply to IndieNuggets, your purchase history, and your browsing
                history. However, IndieNuggets cannot guarantee access to these records by third
                parties for more than three business days after the original information was
                submitted.
            </p>

            <p className="leading-7">
                Although IndieNuggets makes good faith efforts to store the information in a secure
                operating environment that is not available to the public, IndieNuggets cannot
                guarantee complete security. IndieNuggets may store information in an off-site
                location, which may or may not be located in the United States and may not be bound
                by the laws of the United States.
            </p>

            <h2 className="text-lg font-medium pt-4">
                Archiving and display of the Site’s webpages by search engines and other sites
            </h2>
            <p className="leading-7">
                Search engines and other sites not affiliated with IndieNuggets archive or otherwise
                make available IndieNuggets’s individual webpages. IndieNugget has no control over
                third party archiving and search engine sites and cannot guarantee the accuracy of
                any information stored by search engine sites.
            </p>

            <h2 className="text-lg font-medium pt-4">Credit Card Information.</h2>
            <p className="leading-7">
                Stripe handles all purchases made through the Site. Please see Stripe’s
                Privacy Policy regarding payments made through its site. IndieNuggets only stores
                the last four digits of your credit card number if you pay for a monthly
                subscription.
            </p>

            <h2 className="text-lg font-medium pt-4">
                Circumstances in which IndieNuggets may release information
            </h2>
            <p className="leading-7">
                IndieNuggets may disclose information about its users if required to do so by law or
                in the good faith belief that such disclosure is reasonably necessary to respond to
                subpoenas, court orders, or other legal process. IndieNuggets may disclose
                information about its users to law enforcement officers or others, in the good faith
                belief that such disclosure is reasonably necessary to: enforce our Terms of Use;
                respond to claims that any posting or other content violates the rights of
                third-parties; or protect the rights, property, or personal safety of IndieNuggets ,
                its users, or the general public. IndieNuggets may disclose your information to
                third parties for use by IndieNuggets. This may include disclosure to email
                marketing providers to provide targeted marketing for our users. If you do not wish
                to receive email marketing from IndieNuggets, you will have the option to
                unsubscribe from the service. IndieNuggets may disclose your information to other
                business entities, should IndieNuggets plan to merge with or be acquired by that
                business entity. IndieNuggets may disclose your information in the event that you
                dispute any charges incurred by you with Stripe.com and/or any other third party
                service provider from IndieNuggets.
            </p>

            <h2 className="text-lg font-medium pt-4">Geotracking</h2>
            <p className="leading-7">
                IndieNuggets does not do any geotracking at this time through the IndieNuggets
                system; however, certain third party providers, such as Google Analytics, may
                request this information if your device allows it.
            </p>

            <h2 className="text-lg font-medium pt-4">International Users</h2>
            <p className="leading-7">
                IndieNuggets welcomes international users to browse the Site. International Website
                Users consent to all the terms of service and this privacy agreement. International
                users waive any and all remedies that they may have based on the laws of their
                country.
            </p>

            <h2 className="text-lg font-medium pt-4">Cookies and Similar Technologies</h2>
            <p className="leading-7">
                IndieNuggets may use various technologies to collect and store information when you
                visit the Site including cookies or similar technologies to identify your browser or
                device. By using the Site, you consent to the use of such technologies.
            </p>
        </div>
    </Layout>
)

export default PrivacyPage
